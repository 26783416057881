import React, { useEffect } from 'react';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOneSpecies } from '../../actions/species';
import { cellToLatLng } from 'h3-js';

// import MapChart from './MapChart';

import useStyles from './styles';
import MapComponent from './MapComponent';

export const SpeciesDetails = () => {
  const { oneSpecies, isLoading } = useSelector((state) => state.species);
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getOneSpecies(id));
  }, [id, dispatch]);

  if(!oneSpecies) return null;

  var month_dist = new Array(12).fill(0);
  var geo_dist = []; // Check that this datatype actually works
  var min_lat = 90;
  var max_lat = -90;
  var min_long = 180;
  var max_long = -180;

  // Sum up data from all hotspots
  for (let i = 0; i < oneSpecies.hotspots.length; i++){
    let hotspot = oneSpecies.hotspots[i];
    let long_lat = cellToLatLng(hotspot.h3_location);
    if (long_lat[1] < min_lat) min_lat = long_lat[1]
    if (long_lat[1] > max_lat) max_lat = long_lat[1]
    if (long_lat[0] < min_long) min_long = long_lat[0] 
    if (long_lat[0] > max_long) max_long = long_lat[0] 

    geo_dist[i] = {coordinates: long_lat, count: hotspot.observations_count, month_dist: hotspot.month_dist.map(str => parseInt(str, 10))};

    for (let j = 0; j < 12; j++){
      month_dist[j] += parseInt(hotspot.month_dist[j], 10);
    }
  }

  var map_data = {
    locations: geo_dist,
    minLat: min_lat,
    maxLat: max_lat,
    minLong: min_long,
    maxLong: max_long
  }
  var map_center = {
    lat: (max_long + min_long) / 2.0,
    lng: (max_lat + min_lat) / 2.0
  }

  // TODO: Remove this through the creation of the array above and the adding up of the count
  // for (let i = 0; i < month_dist.length; i ++){
  //   let count = month_dist[i]
  //   month_dist[i] = {x: i, y: count}
  // }

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const calendar_data = {
    labels: labels,
    datasets: [
      {
        label: "Species Occurence Seasonality", // TODO: Add custom tooltip
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: month_dist,
      },
    ],
  };

  if(isLoading) {
    return <Paper elevation={6}>
      <CircularProgress size="7em" />
    </Paper>
  }

  return (
    <MapComponent locations={map_data.locations} month_dist={month_dist} center={map_center}/>
    // <Paper style={{ padding: '20px', borderRadius: '15px' }} elevation={6}>
      
    //     <div id="species_details" className={classes.card}>
    //       <div className={classes.section}>
    //         <Typography variant="h4" component="h4">Name: {oneSpecies.species.name}</Typography>
    //         <Typography variant="body2">Species: {oneSpecies.species.species}</Typography>
    //         <Typography variant="body2">Taxon Key: {oneSpecies.species.taxonKey}</Typography>
    //         <Typography variant="body2">Count: {oneSpecies.species.count.toLocaleString()}</Typography>
    //       </div>
    //       {/* <div className={classes.section}>
    //         <Line data={calendar_data} />
    //     </div> */}
        
    //     </div>
    // </Paper>
  )
}
// Implement the following project: https://codepen.io/pailwriter/pen/bGEpeRv