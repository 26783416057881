import React from 'react';
import { Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


const Species = ({ speciesInfo }) => {
    const history = useNavigate();
    const openSpecies = () => history(`/species/${speciesInfo.item_type_id}`);

    return (
        <Card raised elevation={6}>
            <ButtonBase onClick={openSpecies}>
                <CardContent>
                    {/* <Typography variant="body2">Name: {speciesInfo.name}</Typography> */}
                    <Typography variant="body2">Species: {speciesInfo.species}</Typography>
                    <Typography variant="body2">Taxon ID: {speciesInfo.item_type_id}</Typography>
                    <Typography variant="body2">Total Observations: {speciesInfo.count.toLocaleString()}</Typography>
                    <Typography variant="body2">Total Hotspots: {speciesInfo.hotspot_count.toLocaleString()}</Typography>
                    <Typography variant="body2">Total Events: {speciesInfo.event_count.toLocaleString()}</Typography>

                </CardContent>
            </ButtonBase>
        </Card>
    );
};

export default Species;