import axios from 'axios';

const staging = 'http://tellus-alb-274153999.us-west-1.elb.amazonaws.com';//'https://quiet-springs-77620.herokuapp.com';
const prod = 'https://limitless-sands-30505.herokuapp.com';
const test = 'http://localhost:5000';
const url = staging;

export const getMultiSpecies = (page, searchQuery) => axios.post(`${url}/tellus/species/get`, { page: page, search_query: searchQuery }, {headers: { 'Content-Type': 'application/json' }});

export const getOneSpecies = (id) => axios.get(`${url}/tellus/species/${id}`, {headers: { 'Content-Type': 'application/json' }});


export const getEvents = (page,taxonKey) => axios.get(`${url}/tellus/events/?species=${taxonKey}&page=${page}`);
export const getEvent = (id) => axios.get(`${url}/tellus/event/${id}`);

export const getHotspots = (page, taxonKey) => axios.post(`${url}/tellus/hotspots/get`, { taxon_key: taxonKey, page: page }, {headers: { 'Content-Type': 'application/json' }});
export const getHotspot = (id) => axios.post(`${url}/tellus/hotspot/${id}`,  {headers: { 'Content-Type': 'application/json' }});