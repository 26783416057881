import React from 'react';
import { Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


const Hotspot = ({ hotspotInfo }) => {
    const history = useNavigate();
    const openHotspot = () => history(`/hotspots/${hotspotInfo._id}`);

    return (
        <Card raised elevation={6}>
            <ButtonBase onClick={openHotspot}>
                <CardContent>
                    <Typography variant="body2">TaxonKey: {hotspotInfo.taxonKey}</Typography>
                    <Typography variant="body2">H3 Location: {hotspotInfo.h3_location}</Typography>
                    <Typography variant="body2">Count: {hotspotInfo.observations_count.toLocaleString()}</Typography>
                </CardContent>
            </ButtonBase>
        </Card>
    );
};

export default Hotspot;